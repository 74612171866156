import React from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from 'react-swipeable-views-utils';
import './Music.css';

// image imports
import Year from "../img/yearOfTheDolphin.png";
import Halloween from "../img/halloweenSpecial.png";
import Quarantina from "../img/marinaQuarantina.png";
import Porpoise from "../img/purpoisePieParadox.png";
import DolphinOne from "../img/dolphinOne.png";
// import DolphinTwo from "../img/dolphinTwo.png";

// component imports
import MusicMobile from "../music/MusicMobile";

// music import
import Mussels from "../music/growingMussels.mp3";

const Music = () => {

    const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

    return (

        <>

            <div id="musicContainer">
                <div id="musicPlayer">
                    <img src={DolphinOne} alt="The Math Dolphin" id="dolphinImage" />
                    <audio src={Mussels} controls id="audioPlayer">Growing Mussels</audio>
                </div>
                <h1>Discography</h1>
                <AutoPlaySwipeableViews interval={20000} enableMouseEvents id="swipeableViews">

                    {/* Slide 1 - year of the dolphin */}
                    <div className="slide">
                        <h3 id="yearHeader">Year of the Dolphin</h3>
                        <div className="slideRowTop">
                            <div className="slideColLeft">
                                <img src={Year} alt="year of the dolphin" className="slideImage" />
                            </div>
                            <div className="slideColRight">
                                <div className="slideSongs">
                                    <ol className="songList songDolphin">
                                        <li>2 + 2 = dolphin</li>
                                        <li>The Math Dolphin Raps, Again</li>
                                        <li>Nautical Brine</li>
                                        <li>Oil Spill</li>
                                        <li>Year Of The MathROCK Dolphin</li>
                                        <li>Everything Will Be Fine At The Blobfish Pep Assembly</li>
                                        <li>OYSTER!</li>
                                        <li>Don't Get Caught</li>
                                        <li>Trapped In The Net pt1: Dolphin Denial</li>
                                        <li>Trapped In The Net pt2: Aquatic Acceptance</li>
                                    </ol>
                                    <div className="albumDescription">
                                        <p>The Math Dolphin, a renowned mathematician and musician, had been working on his latest album for several years. Entitled "Year of the Dolphin," the album was a fusion of math-inspired music and dolphin-themed lyrics. He spent countless hours researching dolphin behavior and marine biology, pouring over equations and theorems, and experimenting with different instruments and sounds. The result was a unique and captivating masterpiece that blended math and music in a way that had never been done before. The album was a critical and commercial success and solidified the Math Dolphin's reputation as a pioneering artist.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slideRowBottom">
                            <a href="https://themathdolphin.bandcamp.com/album/year-of-the-dolphin" target="_blank" rel="noopener noreferrer" className="musicButton dolphinButton">Listen on bandcamp</a>
                        </div>
                    </div>

                    {/* Slide 2 - halloween special */}
                    <div className="slide">
                        <h3 id="halloweenHeader">The Math Dolphin Halloween Special</h3>
                        <div className="slideRowTop">
                            <div className="slideColLeft">
                                <img src={Halloween} alt="the math dolphin halloween special" className="slideImage" />
                            </div>
                            <div className="slideColRight">
                                <div className="slideSongs">
                                    <ol className="songList songHalloween">
                                        <li>The Great Beginning</li>
                                        <li>Costumed Dolphin Makes A Scare</li>
                                        <li>Shrimp Ghosts</li>
                                        <li>Spooky Bells Ft. Yoko</li>
                                        <li>FishPuncher</li>
                                        <li>Shrimp Ghosts 2: The New Batch</li>
                                        <li>Whales Made This One: It's Weird</li>
                                        <li>A Bunch Of Spooky Screams And Farts</li>
                                        <li>Shrimp Ghosts 3: Dream Warriors</li>
                                        <li>Miami Dolphins Haunted House Party</li>
                                    </ol>
                                    <div className="albumDescription albumHDescription">
                                        <p>The Math Dolphin was determined to create something truly unique and spooky for his latest album, "The Math Dolphin Halloween Special." He spent months researching the mathematics of fear and the science of the uncanny. He delved into the work of horror writers and filmmakers, studying their techniques for creating suspense and unease. He experimented with dark and twisted melodies, dissonant chord progressions, and eerie soundscapes. The final result was an album that blended math and horror in a way that was both terrifying and intellectually stimulating. From the opening track "The Great Beginning" to the closing ballad "The Miami Dolphins Haunted House Party," the album took listeners on a journey through the darker side of mathematics and left them both disturbed and mesmerized.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slideRowBottom">
                            <a href="https://themathdolphin.bandcamp.com/album/the-math-dolphin-halloween-special" target="_blank" rel="noopener noreferrer" className="musicButton .dolphinButton">Listen on bandcamp</a>
                        </div>
                    </div>

                    {/* Slide 3 - marina */}
                    <div className="slide">
                        <h3 id="marinaHeader">Marina Quarantina</h3>
                        <div className="slideRowTop">
                            <div className="slideColLeft">
                                <img src={Quarantina} alt="marina quarantina" className="slideImage" />
                            </div>
                            <div className="slideColRight">
                                <div className="slideSongs">
                                    <ol className="songList songMarina">
                                        <li>Qarientation Log 5: Captain DadGad</li>
                                        <li>Pi Day</li>
                                        <li>CLAM!</li>
                                        <li>The Math Dolphin's Lips</li>
                                        <li>Watch Me Tail Walk</li>
                                        <li>The Doctor Made Me Go On An All Mackerel Diet</li>
                                        <li>P.E.M.D.A.S.(Virtual Classroom)</li>
                                        <li>Eel Feelz</li>
                                        <li>The Great Pacific Garbage Patch In The Sky</li>
                                        <li>Giant Squid Attack</li>
                                        <li>Marina Quarantina</li>
                                    </ol>
                                    <div className="albumDescription albumMDescription">
                                        <p>The Math Dolphin was inspired to create his latest album "Marina Quarantina" during the global pandemic of 2020. He spent his time in quarantine experimenting with new sounds and themes, drawing inspiration from the isolation and confinement of the moment. One of the standout tracks on the album is "The Math Dolphin's Lips," a song that explores the theme of communication and connection in a time of isolation. Another notable track on the album is "Giant Squid Attack," a song that references the deep sea creature as a metaphor for the overwhelming and unpredictable nature of the pandemic. The album as a whole is a reflection on the emotions and experiences of the quarantine period and it's a musical representation of the uncertainty and isolation that people felt during that time.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slideRowBottom">
                            <a href="https://themathdolphin.bandcamp.com/album/marina-quarantina" target="_blank" rel="noopener noreferrer" className="musicButton">Listen on bandcamp</a>
                        </div>
                    </div>

                    {/* Slide 4 - porpoise */}
                    <div className="slide">
                        <h3 id="porpoiseHeader">The Porpoise-Pi Paradox</h3>
                        <div className="slideRowTop">
                            <div className="slideColLeft">
                                <img src={Porpoise} alt="the porpoise pi paradox" className="slideImage" />
                            </div>
                            <div className="slideColRight">
                                <div className="slideSongs">
                                    <ol className="songList songPorpoise">
                                        <li>Into The Sea You Go</li>
                                        <li>The Math Dolphin Rap</li>
                                        <li>Don't Call Me Flipper</li>
                                        <li>Urch1n 1nt3g3r5</li>
                                        <li>Professor Sea Cow, PhD And The Manatee Song</li>
                                        <li>Only Sharks Cheat</li>
                                        <li>Crustacean Salinity Equation</li>
                                        <li>Fish Graph</li>
                                        <li>Out Of The Sea You Come</li>
                                    </ol>
                                    <div className="albumDescription albumPDescription">
                                        <p>The Math Dolphin was inspired to create his latest album, "The Porpoise Paradox," after reading a scientific paper on the way porpoises navigate the ocean. He spent months delving into the mathematics and physics of oceanic motion, using his findings as the basis for the album's themes and sounds. The album's opening track, "Into the Sea You Go," is a dreamy and atmospheric song that evokes the feeling of diving into the ocean's depths. Another notable track on the album is "Out of the Sea You Come," a song that reflects on the porpoise's return to the surface and the sense of transcendence that comes with it. Throughout the album, The Math Dolphin weaves complex mathematical concepts into a musical narrative that is both intellectually stimulating and emotionally evocative.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slideRowBottom">
                            <a href="https://themathdolphin.bandcamp.com/album/the-porpoise-pi-paradox" target="_blank" rel="noopener noreferrer" className="musicButton">Listen on bandcamp</a>
                        </div>
                    </div>
                </AutoPlaySwipeableViews>
            </div>
            <div id="musicMobileDiv">
                <MusicMobile />
            </div>

        </>

    )
}

export default Music;



