// General imports
import React from 'react';
import { Link } from "react-router-dom";
import './Menu.css';

const Menu = (hamburgerstatus, sethamburgerstatus) => {
    return (
        <>
            {/* navigation menu */}
            <div className="burgerMenuDiv">
                <div className="burgerLinks">
                    <Link to="/" className="burgerMenuNavlink" onClick={() => sethamburgerstatus(!hamburgerstatus)}>Home</Link>
                    <Link to="/tour" className="burgerMenuNavlink" onClick={() => sethamburgerstatus(!hamburgerstatus)}>Tour</Link>
                    <Link to="/music" className="burgerMenuNavlink" onClick={() => sethamburgerstatus(!hamburgerstatus)}>Music</Link>
                    <Link to="/merch" className="burgerMenuNavlink" onClick={() => sethamburgerstatus(!hamburgerstatus)}>Merch</Link>
                </div>
            </div>
        </>
    )
}

export default Menu;