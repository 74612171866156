import React from "react";
import "./Home.css";

// image imports
import Background from "../img/mathDolphinTwo.png";
import Mathground from "../img/mathDolphinBackground.png";

const Home = () => {

    return (

        <>

            <div id="homepageContainer">
                <img src={Mathground} alt="dolphin math" id="dolphinMath" />
                <img src={Background} alt="math dolphin background" id="backgroundImage" />
            </div>

        </>

    )
}

export default Home;

// Install a react library to auto play the song on page load - google chrome isn't supporting the autoplay function of the audio tag

// Make sure to credit chatGPT for content creation