import React, { useState } from "react";
import { Link } from "react-router-dom";
import Hamburger from "hamburger-react";
import "./Navbar.css";

// Component imports
import Menu from "./Menu";

const Navbar = () => {

    // State variables (for hamburger menu)
    const [hamburgerOpen, setHamburgerOpen] = useState(false);

    return (

        <>

            {/* Hamburger Menu - Only visible on mobile */}

            <div className="HamburgerMenuDiv" style={{ display: hamburgerOpen ? 'inline' : 'none' }}>
                <Menu hamburgerstatus={hamburgerOpen} sethamburgerstatus={setHamburgerOpen} />
            </div>

            {/* Navbar */}

            <nav>
                <div className="navLinks">
                    <ul className="links">
                        <li><Link to="/" className="navItem">Home</Link></li>
                        <li><Link to="/tour" className="navItem">Tour</Link></li>
                        <li><Link to="/music" className="navItem">Music</Link></li>
                        <li><Link to="/merch" className='navItem'>Merch</Link></li>
                    </ul>
                </div>
                <div className="hamburgerContainer">
                    <Hamburger color="#FFFFFF" duration={0.7} rounded onToggle={toggled => {
                        if (toggled) {
                            setHamburgerOpen(!hamburgerOpen)
                        } else {
                            setHamburgerOpen(!hamburgerOpen)
                        }
                    }} />
                </div>
            </nav>

        </>

    )
}

export default Navbar;