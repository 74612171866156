import React from "react";
import Table from 'react-bootstrap/Table';
import './Tour.css';

const Tour = () => {

    function handleGetTicket() {
        alert("Sold out!");
    }

    return (

        <>

            <div id="tourPageContainer">
                <h1>Tour</h1>
                <Table id="tourTable">
                    <tbody>
                        <tr className="tourRow">
                            <td className="tableContent">6/12/23</td>
                            <td className="tableContent">Sea of Japan</td>
                            <td><button className="tableButton" onClick={handleGetTicket}>Get Tickets</button></td>
                        </tr>
                        <tr className="tourRow">
                            <td className="tableContent">6/13/23</td>
                            <td className="tableContent">Yellow Sea</td>
                            <td><button className="tableButton" onClick={handleGetTicket}>Get Tickets</button></td>
                        </tr>
                        <tr className="tourRow">
                            <td className="tableContent">6/14/23</td>
                            <td className="tableContent">Celebes Sea</td>
                            <td><button className="tableButton" onClick={handleGetTicket}>Get Tickets</button></td>
                        </tr>
                        <tr className="tourRow">
                            <td className="tableContent">6/15/23</td>
                            <td className="tableContent">Bay of Bengal</td>
                            <td><button className="tableButton" onClick={handleGetTicket}>Get Tickets</button></td>
                        </tr>
                        <tr className="tourRow">
                            <td className="tableContent">6/16/23</td>
                            <td className="tableContent indianContent">Indian Ocean Amphitheatre (Featuring PEMDAS Porpoise!)</td>
                            <td className="indianContentMobile">
                                <p>Indian Ocean Amphitheatre</p>
                                <br />
                                <p>(Featuring PEMDAS Porpoise!)</p>
                            </td>
                            <td className="indianContentMobileTwo">
                                <p>Indian Ocean Amphitheatre</p>
                                <br />
                                <br />
                                <br />
                                <p>(Featuring PEMDAS Porpoise!)</p>
                            </td>
                            <td><button className="tableButton" onClick={handleGetTicket}>Get Tickets</button></td>
                        </tr>
                        <tr className="tourRow">
                            <td className="tableContent">6/17/23</td>
                            <td className="tableContent">Mediterranean Sea</td>
                            <td><button className="tableButton" onClick={handleGetTicket}>Get Tickets</button></td>
                        </tr>
                        <tr className="tourRow">
                            <td className="tableContent">6/18/23</td>
                            <td className="tableContent">Gulf of St Lawrence</td>
                            <td><button className="tableButton" onClick={handleGetTicket}>Get Tickets</button></td>
                        </tr>
                        <tr className="tourRow">
                            <td className="tableContent">6/19/23</td>
                            <td className="tableContent">Lake Michigan</td>
                            <td><button className="tableButton" onClick={handleGetTicket}>Get Tickets</button></td>
                        </tr>
                    </tbody>
                </Table>
            </div>

        </>

    )
}

export default Tour;