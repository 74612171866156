import React from 'react';
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

// component imports
import Home from './components/homepage/Home';
import Navbar from './components/navbar/Navbar';
import Tour from './components/tour/Tour';
import Music from './components/music/Music';
import Merch from './components/merch/Merch';

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tour" element={<Tour/>}/>
          <Route path="/music" element={<Music/>}/>
          <Route path="/merch" element={<Merch/>}/>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
