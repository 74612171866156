import React from "react";
import "./Merch.css";

const Merch = () => {

    return (

        <>
        
            <div id="merchContainer">
                <p>Coming Soon!</p>
            </div>
        
        </>

    )
}

export default Merch;